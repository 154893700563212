import React, { useState, useEffect } from "react";

export const PublicBibleList = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bibles, setBibles] = useState([]);

  useEffect(() => {
    fetch(`https://api.scripture.api.bible/v1/bibles`, {
      headers: { "api-key": "aa6d01c0fa6c122a0fb71041544a677e" }
    })
      .then(res => {
        setLoading(false);
        setError(false);
        return res.json();
      })
      .then(res => {
        setBibles(res.data);
      })
      .catch(err => {
        setLoading(false);
        setError(true);
        console.log("err", err);
      });
  }, []);

  if (error) {
    return (
      <div style={{ color: "red" }}>There was an error loading bible list.</div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const biblesByLanguage = bibles.reduce((acc, bible) => {
    const languageName = bible.language.name;
    if (!acc[languageName]) acc[languageName] = [];
    acc[languageName].push(bible);
    return acc;
  }, {});

  const sortedLanguagesKeys = Object.keys(biblesByLanguage).sort((a, b) => {
    return biblesByLanguage[b].length - biblesByLanguage[a].length;
  });

  return (
    <div>
      {sortedLanguagesKeys.map(languageName => {
        return (
          <>
            <h2>{languageName}</h2>
            <ul style={{ lineHeight: 2 }}>
              {biblesByLanguage[languageName].map(({ name, language, id }) => (
                <li key={id}>
                  <strong style={{ color: "black" }}>{name}</strong>
                  <span style={{ color: "black" }}> - </span>
                  <code>{id}</code>
                </li>
              ))}
            </ul>
          </>
        );
      })}
    </div>
  );
};
