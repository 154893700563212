export default {
  colors: {
    primary: "#296BE9",
    background: "#fff",
    sidebar: {
      background: "#ffffff",
      link: "#666",
      heading: "#aaa",
      linkActive: "#13131A",
      itemActive: "#F5F5FA",
      footer: "#A8A8B3",
    },
  },
};
